import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Banner, HomeBg } from "../blocks";
export const wide = true;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  wide,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Banner mdxType="Banner">
      <h1>{`Vitaly Yastremsky`}</h1>
      <p>{`Front-end developer at `}<a parentName="p" {...{
          "href": "https://logisticsos.com/"
        }}>{`LogisticsOS`}</a>{`.`}</p>
      <HomeBg width={400} mdxType="HomeBg">
        <p><img parentName="p" {...{
            "src": "/random-break-circle.svg",
            "alt": "bg-circle"
          }}></img></p>
      </HomeBg>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/blog"
          }}>{`Blog`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://twitter.com/Zvezdocheteg"
          }}>{`Twitter`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/ZvezdocheTag"
          }}>{`GitHub`}</a></li>
      </ul>
    </Banner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      