/** @jsx jsx */
import { jsx } from "theme-ui";

export const Banner = (props) => (
  <div
    sx={{
      pb: [4, 5, 6],
      fontWeight: "bold",
      ul: {
        listStyle: "none",
        display: "flex",
        p: 0,
        m: 0,
      },
      li: {
        mr: 3,
      },
      a: {
        color: "inherit",
        textDecoration: "none",
      },
    }}
  >
    {props.children}
  </div>
);

export const Container = ({ wide, ...props }) => (
  <div
    sx={{
      maxWidth: wide ? "wide" : "container",
    }}
  >
    {props.children}
  </div>
);

export const Tiles = (props) => (
  <div
    sx={{
      ul: {
        listStyle: "none",
        p: 0,
        m: 0,
        display: "grid",
        gridGap: 4,
        gridTemplateColumns: "repeat(auto-fit, minmax(256px, 1fr))",
      },
      h2: {
        fontSize: 2,
      },
      img: {
        display: "block",
        width: 128,
        maxWidth: "100%",
        height: "auto",
        m: "auto",
      },
      a: {
        variant: "styles.navlink",
      },
      ...props.sx,
    }}
  >
    {props.children}
  </div>
);

export const List = (props) => (
  <div
    sx={{
      ul: {
        p: 0,
        m: 0,
        listStyle: "none",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(256px, 1fr))",
        gridGap: 4,
      },
      a: {
        variant: "styles.navlink",
        fontWeight: "bold",
      },
      ...props.sx,
    }}
  >
    {props.children}
  </div>
);

export const ImgWrapper = (props) => (
  <div
    sx={{
      img: {
        p: 0,
        m: 0,
        width: props.width || "100%",
      },
      ...props.sx,
    }}
  >
    {props.children}
  </div>
);

export const HomeBg = (props) => (
  <div
    sx={{
      position: "absolute",
      top: 0,
      right: 0,
      img: {
        p: 0,
        m: 0,
        width: "100%",
      },
      ...props.sx,
    }}
  >
    {props.children}
  </div>
);
